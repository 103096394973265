import { Moment } from 'moment';
export enum OrdersFilterField {
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  Search = 'searchText',
  Client = 'clientId',
  Status = 'status',
  Brand = 'brandId',
  Supplier = 'supplierId',
  Currency = 'currency',
  OrderType = 'orderType',
  Denomination = 'faceValue',
  Region = 'region',
  DownloadStatus = 'downloadStatus',
  IsDownloaded = 'isDownloaded',
}

export interface FilterInputs {
  [OrdersFilterField.DateFrom]: string | Moment;
  [OrdersFilterField.DateTo]: string | Moment;
  [OrdersFilterField.Search]: string;
  [OrdersFilterField.Client]: string;
  [OrdersFilterField.Status]: string;
  [OrdersFilterField.Brand]: string;
  [OrdersFilterField.Supplier]: string;
  [OrdersFilterField.Currency]: string;
  [OrdersFilterField.OrderType]: string;
  [OrdersFilterField.Denomination]: string;
  [OrdersFilterField.Region]: string;
  [OrdersFilterField.DownloadStatus]: string;
  [OrdersFilterField.IsDownloaded]?: boolean;
}
